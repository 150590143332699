import { cloneElement, ReactElement, ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';
import PointerEventsWrapper from '@/storychief/components/PointerEventsWrapper';

type CanWrapperType = {
  children: ReactNode;
  ability: string;
  object: {
    __typename: string;
    can: {
      [ability: string]: boolean;
    };
  };
  disabledProps?: object;
  isPointerEventsWrapper?: boolean;
  message?: string;
};

function CanWrapper({
  children,
  ability,
  object,
  disabledProps = {
    onClick: null,
    href: null,
    disabled: true,
    to: {
      function() {},
    },
  },
  isPointerEventsWrapper = false,
  message = '',
}: CanWrapperType) {
  const can = object.can[ability];
  const name = ability.replace(/_/g, ' '); // "brand_voices" = "brand voices"

  function getTooltipContent() {
    if (message) {
      return message;
    }

    switch (ability) {
      case 'metrics':
      case 'report':
        return `You don't have permission to view the report of this ${object.__typename}. `;
      case 'stories':
      case 'postsets':
      case 'events':
      case 'campaigns':
        return `You don't have permission to manage ${name} for this ${object.__typename}. `;
      case 'activity':
        return `You don't have permission to view the updates of this ${object.__typename}. `;
      case 'brief':
        return `You don't have permission to add or edit a briefing. `;
      case 'calendar':
        return `You don't have permission to view the calendar page. `;
      case 'update_actions':
        return `You don't have permission to this action. `;
      default:
        return `You don't have permission to ${name} this ${object.__typename}. `;
    }
  }

  return (
    <ConditionalWrapper
      condition={!can}
      wrapper={(_children: ReactElement) => (
        <OverlayTrigger
          placement="bottom"
          delayShow={0}
          overlay={<Tooltip id="can-tooltip">{getTooltipContent()}</Tooltip>}
        >
          {cloneElement(_children, disabledProps)}
        </OverlayTrigger>
      )}
    >
      {isPointerEventsWrapper && !can ? (
        <PointerEventsWrapper>{children}</PointerEventsWrapper>
      ) : (
        children
      )}
    </ConditionalWrapper>
  );
}

export default CanWrapper;
